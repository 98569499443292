var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    this.parentNode.classList.toggle("active");
    var panel = this.nextElementSibling;
    if (panel.style.display === "flex") {
      panel.style.display = "none";
    } else {
      panel.style.display = "flex";
    }
  });
}


// accessibility accordion

var accacc = document.getElementsByClassName("accordionacc");
var i;

for (i = 0; i < accacc.length; i++) {
  accacc[i].addEventListener("click", function() {
    this.parentNode.classList.toggle("active");
    var panel = this.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  });
}



// 

var tabs = document.querySelector(".nutration-tab-wraper");
var tabButton = document.querySelectorAll(".tab-button");
var contents = document.querySelectorAll(".content");

tabs.onclick = e => {
  var id = e.target.dataset.id;
  if (id) {
    tabButton.forEach(btn => {
      btn.classList.remove("active");
    });
    e.target.classList.add("active");

    contents.forEach(content => {
      content.classList.remove("active");
    });
    var element = document.getElementById(id);
    element.classList.add("active");
  }
}


// move input next and pre
var container = document.getElementsByClassName("expiryvalidated")[0];
container.onkeyup = function(e) {
  var target = e.srcElement || e.target;
  var maxLength = parseInt(target.attributes["maxlength"].value, 10);
  var myLength = target.value.length;
  if (myLength >= maxLength) {
      var next = target;
      while (next = next.nextElementSibling) {
          if (next == null)
              break;
          if (next.tagName.toLowerCase() === "input") {
              next.focus();
              break;
          }
      }
  }
  // Move to previous field if empty (user pressed backspace)
  else if (myLength === 0) {
      var previous = target;
      while (previous = previous.previousElementSibling) {
          if (previous == null)
              break;
          if (previous.tagName.toLowerCase() === "input") {
              previous.focus();
              break;
          }
      }
  }
}